import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../shared/components/Layouts/Inner';
import ContainerFixed from '../../shared/components/Layouts/ContainerFixed';
import SEO from '../../shared/components/SEO';
import Hero from '../../shared/components/Hero/index';
import HeroImg from '../../shared/images/banners/placeholder.jpg';
import PressReviews from '../../shared/components/PressReviews';
import Block from '../../shared/components/Block';
import WhyChooseUs from '../../shared/components/WhyChooseUs';
import Process from '../../shared/components/Process';
import ServicesInfo from '../../shared/components/Services/ServicesInfo';
import Professionals from '../../shared/components/Testimonial/Professionals';
import Faq from '../../shared/components/Collapse/Faq';
import LocationsList from '../../shared/components/Locations/List';
import Testimonials from '../../shared/components/Testimonial/Testimonials';
import AppDownload from '../../shared/components/AppDownload';

export const query = graphql`
  {
    page: contentfulPage(slug: { eq: "end-of-tenancy-cleaning" }) {
      title
      slug
      frequentlyAskedQuestions {
        id
        title
        childContentfulAccordionBodyRichTextNode {
          id
          json
        }
        __typename
      }
      cleaningProfessionals {
        id
        name
        image {
          file {
            url
            fileName
            contentType
          }
        }
        childContentfulCarouselTextRichTextNode {
          id
          json
        }
        __typename
      }
    }
  }
`;

const EndOfTennancy = ({ data }) => (
  <Layout url="end-of-tenancy-cleaning" darkMode>
    <SEO title="End of Tenancy Cleaning" />
    <Hero heading="End Of Tenancy Cleaning" image={HeroImg} showReviews>
      <p>
        We believe that booking an end of tenancy clean should be simple. We can provide a quote for
        your end of tennancy clean online depending on the size of your home.
      </p>
    </Hero>
    <PressReviews />
    <WhyChooseUs heading="Why choose a Maidupnorth End of Tenancy Clean?">
      <Block>
        <div>
          <h3>Professional Experience</h3>
          <p>
            End of tenancy cleans can be highly daunting, time consuming and not for the faint
            hearted. Getting back your tenancy deposit can be extremely challenging when most
            landlords expect a pristine finish. Save time and gain peace of mind by using our highly
            experienced cleaners. These specialist cleaners have been tried and tested gaining 5
            star reviews throughout the North East. Professionally recognised as the go to cleaning
            company for property management teams and private landlords so know what is expected and
            will guarantee a top end affordable service.
          </p>
        </div>
        <div>
          <h3>Highly Proficient</h3>
          <p>
            We believe in delivering a high end service to all our clients which is 100% guaranteed.
            Our skilled cleaners have been trained to follow landlord approved end of tenancy
            checklists. By following cleaning templates our teams prove to be time efficient and
            highly capable. Each room is given the full Maidupnorth treatment from top to toe. All
            end of tenancy cleans are checked by our experienced supervisors to maintain our award
            winning reputation.
          </p>
        </div>
      </Block>
      <br />
      <Block>
        <div>
          <h3>Reliablility</h3>
          <p>
            Moving home can be an extremely stressful time with an ever growing to do list. Juggling
            so many tasks can lead to anxiety overload unless you run the move with military
            precision and even then the unexpected curve ball may appear. You need a cleaning team
            you can trust to get on with the job to a professional level without cause for concern.
            From your first initial contact through to your key handover we will provide transparent
            communication throughout. We work hard to build positively on our client relationships
            and as a family run company we value your faith in our end of tenancy premium service.
          </p>
        </div>
        <div>
          <h3>Flexibility</h3>
          <p>
            Our busy lives are full of twists and turns and the unexpected. Moving house is no
            different and we understand that flexibility is key in providing our clients a real
            service that allows for movement of dates and key lifts if required. Our app allows you
            to manage your cleaning experience on the go. Please allow us 48 hrs notice of any
            unforeseen changes or get in touch if there are any last minute hiccups. Key lifts can
            be arranged within a 2 mile radius at a small additional charge.
          </p>
        </div>
      </Block>
    </WhyChooseUs>
    <Process />
    <ServicesInfo />
    <Professionals
      heading="Say hi to some of our End of Tenancy specialists"
      data={data.page.cleaningProfessionals}
    />
    <ContainerFixed>
      <Faq data={data.page.frequentlyAskedQuestions} />
    </ContainerFixed>

    <LocationsList />

    <ContainerFixed>
      <Testimonials />
      <AppDownload />
    </ContainerFixed>
  </Layout>
);

export default EndOfTennancy;
